import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SiteSelector from 'components/Header/Navigation/SiteSelector';
import PrimaryMenuHeading from 'components/Header/PrimaryMenuHeading';
import CloseIcon from 'components/icons/CloseIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: `calc(var(--vh) - var(--height-header))`,
    top: 'var(--height-header)',
    left: 0,
    backgroundColor: 'var(--color-bg-navigation)',

    [media.min['desktop.sm']]: {
        top: 0,
    },
});

const SiteSelectorWrapper = styled('div', {
    position: 'absolute',
    left: 0,
    bottom: 0,
    alignItems: 'center',
    width: '100%',
    padding: '12px',
    backgroundColor: 'var(--color-bg-navigation)',
    borderTop: '1px solid var(--color-border-navigation)',
    zIndex: 1,
});

const SelectButton = styled('button', {
    height: '40px',
    width: '100%',
    padding: '0 14px 0 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid var(--color-bg-button-default)',
    backgroundColor: 'var(--color-bg-button-default)',
    color: 'var(--color-text)',
    borderRadius: '78px',
});

const SiteSelectorOverlay = ({ alternateHrefs = [] }) => {
    const [css] = useStyletron();

    const {
        siteSelectorHeading,
        siteSelectorHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
        siteSelectorRedirectLabel,
    } = useSiteSelectorQuery();

    const [isSiteSelectorOpen, setIsSiteSelectorOpen] = useState(true);
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);

    const resetNavigation = () => {
        overlay.close('site-selector');
    };

    return (
        <Wrapper>
            <PrimaryMenuHeading
                isSiteSelectorOpen={isSiteSelectorOpen}
                resetNavigation={resetNavigation}
                text={fm('Menu')}
                $style={{
                    display: ['flex', null, null, null, 'none'],
                    zIndex: 2,
                    borderBottom: '1px solid var(--color-border-navigation)',
                }}
            />
            <SiteSelector
                isFooter
                isOverlay
                alternateHrefs={alternateHrefs}
                setIsSiteSelectorOpen={setIsSiteSelectorOpen}
                siteSelectorHeading={siteSelectorHeading}
                siteSelectorHeadingType={siteSelectorHeadingType}
                siteSelectorParagraph={siteSelectorParagraph}
                siteSelectorRedirectLabel={siteSelectorRedirectLabel}
            />
            <SiteSelectorWrapper>
                <SelectButton
                    onClick={() => {
                        setIsSiteSelectorOpen(!isSiteSelectorOpen);
                        overlay.close('site-selector');
                    }}
                >
                    <div
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        })}
                    >
                        <img
                            className={css({
                                marginRight: '16px',
                                width: '24px',
                                height: '16px',
                            })}
                            src={currentSite?.icon}
                        />
                        <Paragraph fontKeys="Miscellaneous/16_100_-1">{currentSite?.label}</Paragraph>
                    </div>
                    <CloseIcon width="14px" />
                </SelectButton>
            </SiteSelectorWrapper>
        </Wrapper>
    );
};

SiteSelectorOverlay.propTypes = {
    alternateHrefs: PropTypes.array,
};

export default SiteSelectorOverlay;
